// in src/Dashboard.js
import * as React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Title } from 'react-admin';
const dashboard = () => (
    <>
        <Card>
            <Title title="Painel Admin YouTV" />
            <CardContent>Admin YouTV</CardContent>
        </Card>
        <Card>
            <Title title="Cleints ativos"/>
            <CardContent>Teste</CardContent>
        </Card>
    </>

);

export default dashboard
