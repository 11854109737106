import React from 'react';
import './App.css';
import {Admin, fetchUtils, Resource} from "react-admin"
import jsonServerProvider from "ra-data-json-server"
import {ClientsEdit , ClientsCreate, ClientsList} from "./pages/clients"
import Dashboard from "./pages/Dashboard"


import authProvider from "./authProvider"
import Login from './pages/login/index'

const fetchJson = (url = "https://api.youtvdigital.com.br", options = {}) => {
    // @ts-ignore
    options.user = {
        authenticated: true,

        // use the token from local storage
        token: "Bearer "+localStorage.getItem('token')
    };
    return fetchUtils.fetchJson(url, options);
};

const dataProvider = jsonServerProvider('https://api.youtvdigital.com.br', fetchJson)

function App() {


    return (
        <Admin loginPage={Login} authProvider={authProvider} dataProvider={dataProvider} dashboard={Dashboard} >
            <Resource name="clients" list={ClientsList} create={ClientsCreate} edit={ClientsEdit} />
        </Admin>
    );
}

export default App;
