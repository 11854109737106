import {
    Datagrid,
    List,
    TextField,
    ResourceComponentInjectedProps,
    Create,
    SimpleForm,
    Edit,
    SelectInput,
    TextInput,
    EditButton,
    CreateButton,
} from "react-admin";
import axios from "axios";

export const ClientsList = (props: ResourceComponentInjectedProps) => {

    const orderFilters = [
        <TextInput label="Search" source="name" alwaysOn />,
    ];


    return (
        <List {...props} sort={{ field: 'name', order: 'DESC' }} actions={<CreateButton />} filters={orderFilters}>
        <Datagrid >
            <TextField source="name"/>
            <TextField source="cpf"/>
            <EditButton />
        </Datagrid>
    </List>
    );
};

export const ClientsCreate = (props: ResourceComponentInjectedProps) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name"/>
            <TextInput source="cpf"/>
        </SimpleForm>
    </Create>
)

export const ClientsEdit = (props: ResourceComponentInjectedProps) => {

    interface IChoices{
        id: string;
        subs_name: string;
    }

    const choices:IChoices[] = [];
    async function Choices(){
        const requestChoices = await axios.get("https://api.youtvdigital.com.br/subscription")
        const result = requestChoices.data;
        for (let i = 0; i < result.length; i++){
            choices.push(result[i])
        }
    }
    Choices();

    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInput source="name" />
                <TextInput source="cpf" disabled />
                <SelectInput source="subscription" name="id_subscription" choices={choices} optionText="subs_name" optionValue="id" />
            </SimpleForm>
        </Edit>
    )
}
